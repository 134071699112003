import { Pipe } from '@angular/core';
import { BreakpointTypeEnum } from '@shared/types/screen-breakpoints';

@Pipe({
  name: 'appScreenLessThan',
})
export class ScreenLessThanPipe {
  public transform(screenBreakpoint: BreakpointTypeEnum, breakPoint: BreakpointTypeEnum): boolean {
    switch (breakPoint) {
      case 'XSmall':
        return screenBreakpoint === 'XXSmall';

      case 'Small':
        return screenBreakpoint === 'XXSmall' || screenBreakpoint === 'XSmall';

      case 'HalfSmall':
        return (
          screenBreakpoint === 'XXSmall' ||
          screenBreakpoint === 'XSmall' ||
          screenBreakpoint === 'Small'
        );

      case 'Medium':
        return (
          screenBreakpoint === 'XXSmall' ||
          screenBreakpoint === 'XSmall' ||
          screenBreakpoint === 'Small' ||
          screenBreakpoint === 'HalfSmall'
        );

      case 'Large':
        return (
          screenBreakpoint === 'XXSmall' ||
          screenBreakpoint === 'XSmall' ||
          screenBreakpoint === 'Small' ||
          screenBreakpoint === 'HalfSmall' ||
          screenBreakpoint === 'Medium'
        );

      default:
        return false;
    }
  }
}
