<ng-container
  *ngLet="{
    _user: userState.user$ | async,
    _order: orderState.order$ | async,
    _screen: settingsService.screenBreakpoint$ | async
  } as obj"
  [ngVar]="false"
  #overlay="ngVar"
>
  <ng-container *ngIf="obj._screen !== 'XXSmall'; else mobile">
    <h4
      *ngIf="isAvailablePage && obj._order?.city"
      class="subtitle-primary-regular-14"
      [ngClass]="colorState === 'dark' ? 'app-text-color-addit-50' : 'app-text-color-50'"
    >
      г. {{ obj._order?.city.title }}
    </h4>

    <div
      *ngIf="obj._user && isAvailablePage"
      class="app-profile__avatar-container --flex --jc-center w-40-px h-40-px"
      style="border-radius: 50%; overflow: hidden"
      (click)="openProfileMenu()"
      #accountIcon
    >
      <app-svg [src]="obj._user?.avatar || 'assets/img/avatar.svg'" type="none"></app-svg>
    </div>
  </ng-container>

  <ng-template #mobile>
    <div
      *ngIf="obj._user && isAvailablePage"
      class="app-profile__avatar-container --flex --jc-center w-40-px h-40-px"
      style="border-radius: 50%; overflow: hidden; cursor: pointer"
      (click)="openProfileMenu()"
      #accountIcon
    >
      <app-svg [src]="obj._user?.avatar || 'assets/img/avatar.svg'" type="none"></app-svg>
    </div>
  </ng-template>
</ng-container>
