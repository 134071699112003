import { Component, ChangeDetectionStrategy, Inject, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, takeUntil } from 'rxjs/operators';
import { BaseObject } from '@shared/base/base-object';
import { LazyComponent } from '@core/core.types';
import { DialogConfig, DialogData, DIALOG_CONFIG, DIALOG_DATA } from '../dialog.types';
import { ScreenLessThanPipe } from '@shared/pipes/screen-less-than.pipe';
import { BreakpointTypeEnum } from '@shared/types/screen-breakpoints';
import { SettingsService } from '@api/settings.service';

export interface LazyDialogOutletData {
  lazyComponent: LazyComponent;
  data: any;
  dialogConfig: DialogConfig;
}

@Component({
  selector: 'app-lazy-dialog-outlet',
  templateUrl: './lazy-dialog-outlet.component.html',
  styleUrls: ['./lazy-dialog-outlet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.mobile]': `SCREEN_PIPE.transform(settingsService.screenBreakpoint$.value, BreakpointTypeEnum.HalfSmall)`,
  },
})
export class LazyDialogOutletComponent extends BaseObject {
  public lazyComponent: LazyComponent;
  private dialogData: DialogData;
  public SCREEN_PIPE = new ScreenLessThanPipe();

  public readonly BreakpointTypeEnum = BreakpointTypeEnum;

  constructor(
    private injector: Injector,
    private readonly dialogRef: MatDialogRef<LazyDialogOutletComponent>,
    @Inject(MAT_DIALOG_DATA)
    public readonly dialogOutletData: LazyDialogOutletData,
    public settingsService: SettingsService,
  ) {
    super();

    this.dialogData = new DialogData(this.dialogOutletData.data);
    this.dialogData.close = (result) => this.dialogRef.close(result);
    this.dialogData.beforeClosed$ = this.dialogRef.beforeClosed().pipe(
      map(() => null),
      takeUntil(this.destroy$),
    );

    this.lazyComponent = {
      ...this.dialogOutletData.lazyComponent,
      injector: Injector.create({
        parent: this.dialogOutletData.lazyComponent.injector || this.injector,
        providers: [
          {
            provide: DIALOG_DATA,
            useValue: this.dialogData,
          },
          {
            provide: DIALOG_CONFIG,
            useValue: this.dialogOutletData.dialogConfig,
          },
        ],
      }),
    };
  }
}
