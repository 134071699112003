<ng-container *ngIf="isAvailablePage">
  <ng-container
    *ngLet="
      settingsService.screenBreakpoint$
        | async
        | appScreenLessThan: BreakpointTypeEnum.HalfSmall as _mobile
    "
  >
    <ng-container *ngIf="!_mobile; else mobileTemplate">
      <app-svg
        type="none"
        class="--crsr-pntr mrgn-top-16"
        [src]="themeState.logo$ | async"
        (click)="onLogoClick()"
      ></app-svg>

      <app-account-nav [colorState]="colorState$ | async" class="ml-auto"></app-account-nav>
    </ng-container>
  </ng-container>

  <ng-template #mobileTemplate>
    <div class="--flex --jc-space --align-center w-100">
      <app-svg type="none" [src]="themeState.logo$ | async" (click)="onLogoClick()"></app-svg>

      <app-account-nav class="ml-auto"></app-account-nav>
    </div>
  </ng-template>
</ng-container>
