import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, catchError, map, of } from 'rxjs';
import {
  ApartmentParameters,
  Building,
  LivingApartment,
  ParamsForGettingApartments,
  ResidentalComplex,
  SaveApartmentParameters,
} from '@shared/types/living.types';
import { RepairOptionsGroupItem } from '@configurator/modules/repair-type-page/repair-type-page.types';
import { UserProfile } from '@shared/authentication/interfaces';
import { Catalogs, Order, OrderContainer, Project } from '@shared/states/order.state';
import { SettingsService } from './settings.service';
import { SendStatisticsService, StatisticsContextEnum } from '@api/send-statistics.service';
import { Option } from '@configurator/configurator.types';
import { UiResponse } from '@shared/types/api.types';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(
    protected readonly http: HttpClient,
    protected readonly settingsService: SettingsService,
    protected statistics: SendStatisticsService,
  ) {}

  public getComplexesList(): Observable<ResidentalComplex[]> {
    const params = {
      cityIds: '47,78',
      realityType: 'RESALE',
    };

    return this.http
      .get<{ data: ResidentalComplex[] }>(`${this.settingsService.livingApi}partner-api/complex`, {
        params,
        headers: new HttpHeaders({ origin: 'https://ugol.me/' }),
      })
      .pipe(
        catchError((err) => of(null)),
        map((result) => result?.data),
      );
  }

  public getApartment(id: string): Observable<LivingApartment> {
    const body = {
      cityIds: [47, 78],
      id: id,
    };
    return this.http
      .post<{ data: LivingApartment }>(
        `${this.settingsService.livingApi}apartment/extended-card`,
        body,
        {
          headers: new HttpHeaders({ origin: 'https://ugol.me/' }),
        },
      )
      .pipe(
        catchError((err) => of(null)),
        map((result) => result?.data),
      );
  }

  public getLivingMarkers(): Observable<Building[]> {
    const params = {
      cityIds: '47,78',
    };
    return this.http
      .get<{ data: Building[] }>(`${this.settingsService.livingApi}partner-api/building`, {
        params,
        headers: new HttpHeaders({ origin: 'https://ugol.me/' }),
      })
      .pipe(
        catchError((err) => of(null)),
        map((result) => result.data),
      );
  }

  public getApartmentsListByComplex(
    id: string,
    payload: ParamsForGettingApartments,
  ): Observable<LivingApartment[]> {
    if (!payload.roomsCntForFilter) {
      return of(null);
    }

    const params: Record<string, string | string[] | number | number[]> = {};

    params['roomsCntForFilter'] = payload.roomsCntForFilter;

    if (payload.areaTotalFrom) {
      params['areaTotalFrom'] = payload.areaTotalFrom;
    }

    if (payload.areaTotalTo) {
      params['areaTotalTo'] = payload.areaTotalTo;
    }

    if (payload.planningIds) {
      params['planningIds'] = payload.planningIds;
    }

    return this.http
      .post<{ data: LivingApartment[] }>(
        `${this.settingsService.livingApi}partner-api/complex/${id}/apartment`,
        payload,
      )
      .pipe(
        catchError((err) => of(null)),
        map((result) => result?.data),
      );
  }

  public getApartmentsListByBuilding(
    id: number,
    payload: ParamsForGettingApartments,
  ): Observable<LivingApartment[]> {
    if (!payload.roomsCntForFilter) {
      return of(null);
    }

    const params: Record<string, string | string[] | number | number[]> = {};

    params['roomsCntForFilter'] = payload.roomsCntForFilter;

    if (payload.areaTotalFrom) {
      params['areaTotalFrom'] = payload.areaTotalFrom;
    }

    if (payload.areaTotalTo) {
      params['areaTotalTo'] = payload.areaTotalTo;
    }

    if (payload.planningIds) {
      params['planningIds'] = payload.planningIds;
    }

    return this.http
      .post<{ data: LivingApartment[] }>(
        `${this.settingsService.livingApi}partner-api/building/${id}/apartment`,
        payload,
      )
      .pipe(
        catchError((err) => of(null)),
        map((result) => result?.data),
      );
  }

  public getListApartmentLayout(id: number) {
    return this.http
      .post<{ data: [] }>(`${this.settingsService.livingApi}partner-api/complex/${id}/planning`, {
        observe: 'response',
      })
      .pipe(
        catchError((err) => of(null)),
        map((result) => result?.data),
      );
  }

  public getOrder(onlyProjects = false): Observable<OrderContainer> {
    return this.http
      .get<OrderContainer>(`${this.settingsService.v2}client/configurator/get-order`, {
        params: { onlyProjects },
      })
      .pipe(catchError((err) => of(null)));
  }

  public getAnonymousOrder(saveOrder: boolean): Observable<unknown> {
    return this.http
      .get<unknown>(
        `${this.settingsService.v2}client/configurator/get-anonymous-order?${saveOrder}`,
      )
      .pipe(catchError((err) => of(null)));
  }
  public getApartmentParameters(params: HttpParams): Observable<ApartmentParameters> {
    return this.http
      .get<ApartmentParameters>(`${this.settingsService.v2}client/configurator/get-front-params`, {
        params,
      })
      .pipe(catchError((err) => of(null)));
  }

  public sendApartmentSettings(
    params: SaveApartmentParameters,
    hash?: string,
  ): Observable<Order['apart_param']> {
    return this.http
      .post<{ apart_param: Order['apart_param'] }>(
        `${this.settingsService.v2}client/configurator/save-params`,
        params,
        {
          headers: this.statistics.createContextHeaders(StatisticsContextEnum.FLAT_PARAMS, hash),
        },
      )
      .pipe(
        map((result) => result.apart_param),
        catchError((err) => of(null)),
      );
  }

  public getCatalogList(): Observable<Catalogs> {
    return this.http
      .get<Catalogs>(`${this.settingsService.v2}client/configurator/get-catalog`)
      .pipe(catchError((err) => of(null)));
  }

  public updateRepairType(level: number): Observable<OrderContainer> {
    return this.http
      .post<OrderContainer>(`${this.settingsService.v2}client/configurator/save-repair-type`, {
        repairType: level,
      })
      .pipe(catchError((err) => of(null)));
  }

  public setActiveProject(projectId: number, hash?: string): Observable<Project> {
    return this.http
      .post<Project>(
        `${this.settingsService.v2}client/configurator/set-active-project`,
        {
          projectId: projectId,
        },
        {
          headers: this.statistics.createContextHeaders(
            StatisticsContextEnum.REPAIR_OPTIMIZATION_SCREEN,
            hash,
          ),
        },
      )
      .pipe(catchError((err) => of(null)));
  }

  public createProject(
    project: {
      name: string;
      repairTypeId: number;
      templateId: number;
    },
    hash?: string,
  ): Observable<Project> {
    return this.http
      .post<Project>(`${this.settingsService.v2}client/configurator/create-self-project`, project, {
        headers: this.statistics.createContextHeaders(
          StatisticsContextEnum.REPAIR_OPTIMIZATION_SCREEN,
          hash,
        ),
      })
      .pipe(catchError((err) => of(null)));
  }

  public cloneProject(
    projectId: number,
    hash?: string,
    ctx = StatisticsContextEnum.REPAIR_OPTIMIZATION_SCREEN,
  ): Observable<Project> {
    return this.http
      .post<Project>(
        `${this.settingsService.v2}client/configurator/clone-project`,
        {
          projectId: projectId,
        },
        {
          headers: this.statistics.createContextHeaders(ctx, hash),
        },
      )
      .pipe(catchError((err) => of(null)));
  }

  public deleteProject(projectId: number, hash?: string): Observable<any> {
    return this.http
      .delete(`${this.settingsService.v2}client/configurator/delete-project`, {
        params: {
          projectId,
        },
        headers: this.statistics.createContextHeaders(
          StatisticsContextEnum.REPAIR_OPTIMIZATION_SCREEN,
          hash,
        ),
      })
      .pipe(catchError((err) => of(null)));
  }

  public updateBrigadeLevel(level: number, hash?: string): Observable<Project> {
    return this.http
      .post<Project>(
        `${this.settingsService.v2}client/configurator/save-brigade-level`,
        {
          brigadeLevel: level,
        },
        {
          headers: this.statistics.createContextHeaders(
            StatisticsContextEnum.MAIN_BOARD_BRIGADE,
            hash,
          ),
        },
      )
      .pipe(catchError((err) => of(null)));
  }

  public updateMaterialLevel(level: number): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.settingsService.v2}client/configurator/save-material-level`, {
        materialLevel: level,
      })
      .pipe(catchError((err) => of(false)));
  }

  public getMainOptions(): Observable<UiResponse<Option[]>> {
    return this.http
      .get<Option[]>(`${this.settingsService.v2}client/configurator/option/get-main`)
      .pipe(
        map((result) => {
          return { error: null, data: result };
        }),
        catchError((err) => of({ error: err, data: null })),
      );
  }

  public getRepairOptions(hash?: string): Observable<RepairOptionsGroupItem[]> {
    return this.http
      .get<RepairOptionsGroupItem[]>(`${this.settingsService.v2}client/configurator/get-options`, {
        headers: this.statistics.createContextHeaders(StatisticsContextEnum.MAIN_BOARD, hash),
      })
      .pipe(catchError((err) => of(null)));
  }

  public updateRepairTypeOptions(options: unknown, hash?: string): Observable<Project> {
    return this.http
      .post<Project>(`${this.settingsService.v2}client/configurator/save-options`, options, {
        headers: this.statistics.createContextHeaders(
          StatisticsContextEnum.REPAIR_OPTIMIZATION_SCREEN,
          hash,
        ),
      })
      .pipe(catchError((err) => of(null)));
  }

  public resetParameters(): Observable<boolean> {
    return this.http
      .get<boolean>(`${this.settingsService.v2}client/configurator/reset-params`)
      .pipe(catchError((err) => of(false)));
  }

  public resetOptions(): Observable<boolean> {
    return this.http
      .get<boolean>(`${this.settingsService.v2}client/configurator/reset-options`)
      .pipe(catchError((err) => of(false)));
  }

  public resetOrder(hash?: string): Observable<boolean> {
    return this.http
      .get<boolean>(`${this.settingsService.v2}client/configurator/clear-order`, {
        headers: this.statistics.createContextHeaders(
          StatisticsContextEnum.MAIN_BOARD_CONTEXT_MENU,
          hash,
        ),
      })
      .pipe(catchError((err) => of(false)));
  }

  public getProfile(): Observable<UserProfile> {
    return this.http
      .get<UserProfile>(`${this.settingsService.v2}client/configurator/get-profile`)
      .pipe(catchError((err) => of(null)));
  }

  public updateProfile(formData: FormData): Observable<UserProfile> {
    return this.http
      .post<UserProfile>(`${this.settingsService.v2}client/configurator/update-profile`, formData)
      .pipe(catchError((err) => of(null)));
  }

  public deleteAvatar(): Observable<UserProfile> {
    return this.http.get<UserProfile>(
      `${this.settingsService.v2}client/configurator/update-profile-delete-avatar`,
    );
  }

  public downloadEstimate(hash?: string): Observable<Blob> {
    return this.http.get(`${this.settingsService.v2}client/configurator/upload-estimate-to-excel`, {
      responseType: 'blob',
      headers: this.statistics.createContextHeaders(
        StatisticsContextEnum.MAIN_BOARD_DOWNLOAD,
        hash,
      ),
    });
  }

  public downloadFiles(
    url: string,
    hash?: { context: StatisticsContextEnum; hash: string },
  ): Observable<Blob> {
    if (hash) {
      return this.http.get(url, {
        responseType: 'blob',
        headers: this.statistics.createContextHeaders(hash.context, hash.hash),
      });
    }

    return this.http.get(url, {
      responseType: 'blob',
    });
  }
}
