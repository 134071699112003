import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { filter, first, takeUntil } from 'rxjs';
import { SettingsService } from '@shared/api/settings.service';
import { AuthenticationService } from '@shared/authentication/authentication.service';
import { BaseObject } from '@shared/base/base-object';
import { OrderState } from '@shared/states/order.state';
import { UserState } from '@shared/states/user.state';
import { DIALOG_DATA, DialogData } from '@ui/dialog/dialog.types';
import { ApiService } from '@api/api.service';
import {
  SendStatisticsService,
  StatisticsActionKey,
  StatisticsContextEnum,
} from '@api/send-statistics.service';
import { VarDirective } from '@shared/directives/var.directive';
import { ConfirmationType } from './profile-menu.types';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent extends BaseObject {
  public isLoginPage = location.pathname === '/login';
  private statisticsResetOrderHash = '';

  constructor(
    public orderState: OrderState,
    public userState: UserState,
    public settingsService: SettingsService,
    private router: Router,
    private authService: AuthenticationService,
    private apiService: ApiService,
    private statistics: SendStatisticsService,
    @Inject(DIALOG_DATA) public dialogData: DialogData<string, void>,
  ) {
    super();
  }

  public toProfile(): void {
    this.dialogData.close();
    this.router.navigate(['account']);
  }

  public toLogin(): void {
    this.dialogData.close();
    this.statistics
      .sendStat(StatisticsActionKey.MENU_AUTH, StatisticsContextEnum.MAIN_BOARD_CONTEXT_MENU)
      .subscribe();
  }

  public logout(): void {
    this.authService
      .logout()
      .pipe(first(), takeUntil(this.destroy$))
      .subscribe(() => location.replace('login'));
  }

  public onClickResetOrderOrLogout(
    confirmationDialog: VarDirective<ConfirmationType>,
    type: ConfirmationType,
  ): void {
    confirmationDialog.value = type;
  }

  public resetOrder(): void {
    this.statisticsResetOrderHash = this.statistics.sendStatAndSetHeadersToEndpoint(
      StatisticsActionKey.MENU_CLEAR_PARAMS,
      StatisticsContextEnum.MAIN_BOARD_CONTEXT_MENU,
    );

    this.apiService
      .resetOrder(this.statisticsResetOrderHash)
      .pipe(
        first(),
        filter((result) => !!result),
      )
      .subscribe((result) => location.replace('configurator'));
  }
}
