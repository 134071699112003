<ng-container
  *ngLet="{
    _user: userState.user$ | async,
    _order: orderState.order$ | async,
    _mobile: (settingsService.screenBreakpoint$ | async) === 'XXSmall'
  } as obj"
  [ngVar]="null"
  #confirmationDialog="ngVar"
>
  <div
    class="--flex --col --overflow-hidden app-bg-main app-border-radius-12"
    (click)="$event.stopPropagation()"
  >
    <div
      *ngIf="obj._mobile && obj._order?.city?.title"
      class="--flex --align-center pdng-lft-44 pdng-y-12 app-border-bottom"
    >
      <span>г. {{ obj._order.city.title }}</span>
    </div>

    <div
      *ngIf="(!obj._user || obj._user.anonymous) && !isLoginPage"
      class="--flex --gap-8 --align-center --crsr-pntr app-profile-menu__button pdng-12"
      [routerLink]="'/login'"
      (click)="toLogin()"
    >
      <app-svg type="none" src="assets/img/auth.svg"></app-svg>

      <span class="subtitle-primary-medium-16">Авторизоваться</span>
    </div>

    <div
      class="--flex --gap-8 --align-center --crsr-pntr app-profile-menu__button pdng-12"
      (click)="onClickResetOrderOrLogout(confirmationDialog, 'reset-order')"
    >
      <app-svg type="none" src="/assets/img/refresh-2.svg"></app-svg>

      <span class="subtitle-primary-medium-16">Сбросить все параметры</span>
    </div>

    <ng-container *ngIf="obj._user && !obj._user?.anonymous && !isLoginPage">
      <div
        class="--flex --gap-8 --align-center --crsr-pntr app-profile-menu__button app-border-bottom pdng-12"
        (click)="toProfile()"
      >
        <app-svg type="none" src="assets/img/profile.svg"></app-svg>

        <span class="subtitle-primary-medium-16">Перейти в профиль</span>
      </div>

      <div
        class="--flex --gap-8 --align-center --crsr-pntr app-profile-menu__button pdng-12"
        (click)="onClickResetOrderOrLogout(confirmationDialog, 'logout')"
      >
        <app-svg type="none" src="assets/img/logout-2.svg"></app-svg>

        <span class="subtitle-primary-medium-16">Выход</span>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="confirmationDialog.value">
    <app-dialog-notification
      [calculatePosition]="!obj._mobile"
      (closeEvent)="confirmationDialog.value = null"
    >
      <app-dialog-notification-title>
        <span class="heading-primary-bold-24">{{
          confirmationDialog.value === 'logout' ? 'Выход' : 'Сброс параметров'
        }}</span>
      </app-dialog-notification-title>

      <app-dialog-notification-description>
        {{
          confirmationDialog.value === 'logout'
            ? 'Вы хотите выйти из системы?'
            : 'Вы хотите сбросить все параметры и опции?'
        }}
      </app-dialog-notification-description>

      <app-dialog-notification-footer>
        <div class="--flex --gap-10 w-100 pdng-y-20 pdng-x-0">
          <app-button
            type="secondary"
            class="app-border app-border-danger w-50"
            (click)="confirmationDialog.value = null"
          >
            <span class="app-text-danger"> Нет </span>
          </app-button>

          <app-button
            type="danger"
            class="w-50"
            (click)="
              confirmationDialog.value === 'logout' ? logout() : resetOrder();
              confirmationDialog.value = null
            "
          >
            Да
          </app-button>
        </div>
      </app-dialog-notification-footer>
    </app-dialog-notification>
  </ng-container>
</ng-container>
